import qs from 'qs'
import client from '../thinMintClient'
import { resolveComment } from '../resolvers'

export interface Comment {
  answer: Comment | null
  approved: string | null
  campaign: string
  created: string
  id: number
  name: string
  parent: number | null
  text: string
  user: string
  financialTies: boolean
  issuerResponse: boolean
}

export interface CommentList {
  count: number
  next: string | null
  previous: string | null
  results: Comment[]
}

export interface CommentPayload
  extends Omit<Comment, 'financialTies' | 'issuerResponse'> {
  financial_ties: boolean
  issuer_response: boolean
}

export interface CommentListPayload {
  count: number
  next: string | null
  previous: string | null
  results: CommentPayload[]
}

export interface Filters {
  unanswered?: true
}

export interface Params extends Filters {
  campaign: string
  page?: number
}

export interface CommentReq {
  parentId?: number
  userId: string
  text: string
  campaign: string
}

const baseEndpoint = '/campaign-public-comment'

export const getComments = (obj: Params) =>
  client
    .get(`${baseEndpoint}/${qs.stringify(obj, { addQueryPrefix: true })}`)
    .then((res: { data: CommentListPayload }) => {
      const results = res.data.results.map(
        resolveComment
      ) as unknown as Comment[]
      return {
        ...res.data,
        results,
      }
    })

export const postComment = (obj: CommentReq) =>
  client
    .post(`${baseEndpoint}/`, {
      campaign: obj.campaign,
      text: obj.text,
      user: obj.userId,
    })
    .then(
      (res: { data: CommentPayload }) =>
        resolveComment(res.data) as unknown as Comment
    )

export const postResponse = (obj: CommentReq) =>
  client
    .post(`${baseEndpoint}/`, {
      campaign: obj.campaign,
      text: obj.text,
      user: obj.userId,
      parent: obj.parentId,
      issuer_response: true,
    })
    .then(
      (res: { data: CommentPayload }) =>
        resolveComment(res.data) as unknown as Comment
    )

export default {
  baseEndpoint,
  getComments,
  postComment,
  postResponse,
}

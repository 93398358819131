import React, { CSSProperties, useMemo } from 'react'
import { Asset } from 'contentful'
import NextImage from 'next/image'
import qs from 'qs'

// Contentful Docs: https://www.contentful.com/developers/docs/references/images-api/
interface Props {
  className?: string
  fill?:
    | 'center'
    | 'top'
    | 'right'
    | 'left'
    | 'bottom'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces'
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb'
  height?: number
  image: Asset
  lazy?: boolean
  style?: CSSProperties
  width?: number
  [key: string]: any
}

const Image: React.FC<Props> = ({
  className,
  fill,
  fit,
  height: setHeight,
  image,
  lazy,
  style,
  width: setWidth,
  ...props
}) => {
  const alt = image.fields.description || ''
  const { details, url } = image.fields?.file || {}
  const params = qs.stringify(
    {
      f: fill,
      fit,
      h: setHeight,
      w: setWidth,
    },
    {
      addQueryPrefix: true,
    }
  )

  const [height, width] = useMemo(() => {
    // default 16:9 ratio
    const widthRatio = details.image
      ? details.image?.width / details.image?.height
      : 16 / 9
    const heightRatio = details.image
      ? details.image?.height / details.image?.width
      : 9 / 16

    if (setHeight || setWidth) {
      const calcHeight = setHeight || Number(setWidth) * heightRatio
      const calcWidth = setWidth || Number(setHeight) * widthRatio
      return [calcHeight, calcWidth]
    }

    return [details.image?.height, details.image?.width]
  }, [details.image, setHeight, setWidth])

  return (
    <div className={className} style={style}>
      <NextImage
        alt={alt}
        className={className}
        height={height}
        loading={lazy ? 'lazy' : 'eager'}
        src={`https:${url}${params}`}
        width={width}
        {...props}
      />
    </div>
  )
}

export default Image

import client from '../thinMintClient'
import {
  resolveParty,
  resolvePartyPayload,
  resolvePaybackMethodPayload,
} from './resolver'
import { PartyPayload, PartyForm, PaybackForm } from './types'

const baseEndpoint = '/parties'

export const getAuthenticatedParty = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: { results: PartyPayload[] } }) =>
      resolveParty(res.data.results[0])
    )

export const createParty = (party: PartyForm) =>
  client
    .post(`${baseEndpoint}/`, resolvePartyPayload(party))
    .then((res: { data: PartyPayload }) => resolveParty(res.data))

export const updateParty = (partyId: number, party: PartyForm) =>
  client
    .put(`${baseEndpoint}/${partyId}/`, resolvePartyPayload(party))
    .then((res: { data: PartyPayload }) => resolveParty(res.data))

export const upsertPartyPaybackMethod = (partyId: number, form: PaybackForm) =>
  client.post(
    `${baseEndpoint}/${partyId}/payback_method/`,
    resolvePaybackMethodPayload(form)
  )

export default {
  baseEndpoint,
  getAuthenticatedParty,
  createParty,
  updateParty,
  upsertPartyPaybackMethod,
}

import client from '../thinMintClient'
import { PaymentForm } from './types'
import { resolvePaymentForm } from './resolver'

const baseEndpoint = '/payments'

export const createPayment = (investment: string, payment: PaymentForm) =>
  client.post(`${baseEndpoint}/`, resolvePaymentForm(investment, payment))

export default {
  baseEndpoint,
  createPayment,
}

import client from './campaignDetailsClient'

interface CampaignDetailsRequest {
  slug: string
}

export const getCampaignDetails = ({ slug }: CampaignDetailsRequest) => ({
  key: `production/${slug}.json`,
  request: () => client.get(`production/${slug}.json`),
  resolve: (res: { data: any }) => res.data,
})

interface LivestreamRequest {
  video: string
}

export const getLivestreamComments = ({ video }: LivestreamRequest) => ({
  key: `livestreams/${video}.json`,
  request: () => client.get(`livestreams/${video}.json`),
  resolve: (res: { data: any }) => res.data.Items,
  fallbackData: [],
})

export default {
  getCampaignDetails,
  getLivestreamComments,
}

import React, { HTMLInputTypeAttribute } from 'react'
import { Input } from '../FormBuilder/types'

const FormInputDefault: React.FC<Input & { type?: HTMLInputTypeAttribute }> = ({
  type = 'text',
  value,
  ...rest
}) => <input {...rest} type={type} value={value || ''} />

export default FormInputDefault

import client from '../thinMintClient'
import {
  resolveEntity,
  resolveEntityPayload,
  resolveFilePayload,
} from '../resolvers'

const baseEndpoint = '/entities'

export interface EntityDocument {
  id: string
  name: string
}

export interface PayloadEntityDocument {
  documentid: string
  documentTitle: string
}

interface BaseEntity {
  id: string
  name: string
  email: string
  address1: string
  address2?: string
  city: string
  state: string
  country: string
  ein?: string
}

export interface Entity extends BaseEntity {
  entityType: string
  accountId: string
  phoneNumber: string
  postalCode: string
  hasEIN?: boolean
}

export interface PayloadEntity extends BaseEntity {
  entity_type: string
  account_id: string
  phone_number: string
  postal_code: string
  has_EIN?: boolean
}

export const getEntities = () => ({
  key: `${baseEndpoint}/`,
  request: () => client.get(`${baseEndpoint}/`),
  resolve: (res: { data: { results: PayloadEntity[] } }) =>
    res.data.results.map(resolveEntity),
})

export const createEntity = () => ({
  request: (entity: Entity) =>
    client.post(`${baseEndpoint}/`, resolveEntityPayload(entity)),
  resolve: (res: { data: PayloadEntity }) => {
    return resolveEntity(res.data)
  },
})

export const updateEntity = (entityId: string) => ({
  request: (entity: Entity) =>
    client.put(`${baseEndpoint}/${entityId}/`, resolveEntityPayload(entity)),
  resolve: (res: { data: PayloadEntity }) => {
    return resolveEntity(res.data)
  },
})

export const getEntityDocuments = (entityId: string) => ({
  key: `${baseEndpoint}/${entityId}/list_documents/`,
  request: () => client.get(`${baseEndpoint}/${entityId}/list_documents/`),
  resolve: (res: { data: PayloadEntityDocument[] }) => {
    return res.data.map(({ documentid, documentTitle }) => ({
      id: documentid,
      name: documentTitle,
    }))
  },
})

export const uploadEntityDocument = (entityId: string) => ({
  request: (file: File) =>
    client.post(
      `${baseEndpoint}/${entityId}/upload_document/`,
      resolveFilePayload(file)
    ),
})

export const removeEntityDocument = (entityId: string) => ({
  request: (id: string) =>
    client
      .delete(`${baseEndpoint}/${entityId}/delete_document/${id}/`)
      .then((res) => {
        if (res.data !== 'Document deleted successfully') {
          throw new Error('Unable to delete document. Please try again.')
        }
        return res.data
      }),
})

export default {
  baseEndpoint,
  getEntities,
  createEntity,
  updateEntity,
  getEntityDocuments,
  uploadEntityDocument,
  removeEntityDocument,
}

import client from '../thinMintClient'
import { resolveMetabase } from '../resolvers'

const baseEndpoint = '/metabase'

export interface MetabaseDashboard {
  id: string
  campaignSlug: string
  url: string
  expiresAt: string
}

export interface MetabaseDashboardPayload {
  id: string
  campaign_slug: string
  url: string
  expires_at: string
}

export const getMetabaseByCampaign = (
  metabaseId: string,
  campaignSlug: string
) =>
  client
    .get(
      `${baseEndpoint}/dashboard/${metabaseId}/?campaign_slug=${campaignSlug}`
    )
    .then(
      (res: { data: MetabaseDashboardPayload }) =>
        resolveMetabase(res.data) as MetabaseDashboard
    )

export const getMetabase = (metabaseId: string) =>
  client
    .get(`${baseEndpoint}/dashboard/${metabaseId}/`)
    .then(
      (res: { data: MetabaseDashboardPayload }) =>
        resolveMetabase(res.data) as MetabaseDashboard
    )

export default {
  baseEndpoint,
  getMetabase,
  getMetabaseByCampaign,
}

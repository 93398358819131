import { casingUtil } from '@/utils'
import {
  resolveBirthday,
  resolveBirthdayPayload,
  resolvePhoneNumber,
} from '../resolvers'
import {
  Party,
  PartyForm,
  PartyPayload,
  PaybackForm,
  PaybackRequest,
} from './types'

export const resolveParty = (party: PartyPayload) => {
  const { accredited_status, date_of_birth, phone_number, ...rest } = party

  return {
    ...casingUtil.snakeToCamel(rest),
    accredited: accredited_status !== 'Not Accredited',
    birthday: resolveBirthday(date_of_birth),
    phoneNumber: resolvePhoneNumber(phone_number),
  } as Party
}

export const resolvePartyPayload = ({
  accredited,
  birthday,
  phoneNumber,
  ...rest
}: PartyForm) => {
  return {
    ...casingUtil.camelToSnake(rest),
    accredited_status: accredited ? 'Self Accredited' : 'Not Accredited',
    date_of_birth: resolveBirthdayPayload(birthday),
    phone_number: phoneNumber,
  } as PartyPayload
}

export const resolvePaybackMethodPayload = ({
  paybackMethod: payback_method,
  userHandle: user_handle = '',
  achAccountType: ach_account_type = '',
  achAccountNumber: ach_account_number = '',
  achRoutingNumber: ach_routing_number = '',
}: PaybackForm): PaybackRequest => {
  if (payback_method === 'ach') {
    return {
      payback_method,
      ach_account_type,
      ach_account_number,
      ach_routing_number,
    }
  }

  return {
    payback_method,
    user_handle,
  }
}

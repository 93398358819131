import client from '../thinMintClient'
import { resolvePersona } from '../resolvers'

export interface BasePersona {
  value: string
  label: string
  requiresEIN?: boolean
}

export interface Persona extends BasePersona {
  requiredDocuments: string
  maxSize: number
  accept: string
}

export interface PayloadPersona extends BasePersona {
  required_documents?: string
  max_file_size?: number
  allowed_file_types?: string
}

export interface EntityDocument {
  id: string
  name: string
}

const baseEndpoint = '/personas'

export const getPersonas = () => ({
  key: `${baseEndpoint}/`,
  request: () => client.get(`${baseEndpoint}/`),
  resolve: (res: { data: { personas: PayloadPersona[] } }) =>
    res.data.personas.map(resolvePersona),
})

export default {
  baseEndpoint,
  getPersonas,
}

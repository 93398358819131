import React from 'react'
import classNames from 'classnames'
import ButtonBase from '../../ButtonBase'
import ABTester, { ABTestProps } from '@/components/ABTester'
import { Eye, EyeSlash } from '@/components/svg'

export const getInputClassName = (
  variationKey: 'on' | 'off',
  hasError: boolean,
  disabled = false,
  placeholder = '',
  prefix?: string
) =>
  classNames({
    'w-full border-gray-6 focus:outline-none bg-transparent appearance-none':
      true,
    'h-12 border rounded-[4px] px-2 hover:ring-1 focus:ring-1':
      variationKey === 'on',
    'focus:ring-oxide-3 focus:border-oxide hover:ring-gray-6':
      variationKey === 'on' && !hasError,
    'border-red ring-red focus:ring-red': variationKey === 'on' && hasError,
    'h-9 border-b focus:border-oxide-3': variationKey !== 'on',
    'peer placeholder-transparent': variationKey !== 'on' && !placeholder,
    'opacity-50 pointer-events-none': disabled,
    'pl-6': !!prefix,
  })

export const getLabelClassName = (
  variationKey: 'on' | 'off',
  hasError: boolean,
  disabled = false
) =>
  classNames({
    'absolute left-0 top-0': true,
    'text-gray-5': !hasError,
    'text-red': hasError,
    'top-[-0.25rem] text-xs': variationKey === 'on',
    'pointer-events-none text-xs transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-[1.35rem] peer-focus:top-0 peer-focus:text-xs':
      variationKey !== 'on',
    'pointer-events-none': disabled,
  })

export interface Props {
  name: string
  label: string | JSX.Element
  value?: string
  placeholder?: string
  hasError: boolean
  disabled?: boolean
  labelClassName: string
  inputClassName: string
  isSecret?: boolean
  isSecretMasked?: boolean
  toggleSecret?: () => void
  prefix?: string
  children: React.ReactNode
  className?: string
}

export const InputLabelWrap = ({
  name,
  label,
  value,
  placeholder,
  labelClassName,
  inputClassName,
  isSecret,
  isSecretMasked,
  toggleSecret,
  prefix,
  children,
  className,
}: Omit<Props, 'hasError' | 'disabled'>) => {
  return (
    <div className={classNames('relative pt-4', className)}>
      {prefix && (
        <div className="absolute left-2 top-[50%] -translate-y-[50%] mt-2 text-right w-4">
          {prefix}
        </div>
      )}

      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement, {
          name,
          id: name,
          placeholder,
          className: inputClassName,
        })
      })}

      <label id={`${name}-label`} htmlFor={name} className={labelClassName}>
        {label}
      </label>

      {isSecret && value && (
        <ButtonBase
          onClick={toggleSecret}
          type="button"
          className="absolute right-2 top-[50%] -translate-y-[50%] mt-2 focus:ring-1 focus:ring-oxide"
          aria-label="Toggle secret"
        >
          {isSecretMasked ? (
            <Eye className="w-6 text-oxide" />
          ) : (
            <EyeSlash className="w-6 text-oxide" />
          )}
        </ButtonBase>
      )}
    </div>
  )
}

const ABInputLabelWrap = ({
  name,
  label,
  placeholder,
  prefix,
  hasError,
  disabled,
  ...rest
}: Omit<Props, 'labelClassName' | 'inputClassName'>) => {
  const defaultPlaceholder = typeof label === 'string' ? label : name

  return (
    <ABTester name="border_inputs">
      {({ decision }: ABTestProps) => {
        const inputClassName = getInputClassName(
          decision.variationKey,
          hasError,
          disabled,
          placeholder,
          prefix
        )

        const labelClassName = getLabelClassName(
          decision.variationKey,
          hasError,
          disabled
        )

        const resolvedPlaceholder =
          decision.variationKey === 'on'
            ? placeholder
            : placeholder || defaultPlaceholder

        return (
          <InputLabelWrap
            {...rest}
            name={name}
            label={label}
            placeholder={resolvedPlaceholder}
            prefix={prefix}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
          />
        )
      }}
    </ABTester>
  )
}

export default ABInputLabelWrap

import CardModal from '@/components/common/CardModal'
import Markdown from '@/components/Markdown'

export default function MarkdownModal({ isOpen, setIsOpen, info }) {
  return (
    <CardModal isOpen={isOpen} setIsOpen={() => setIsOpen(false)}>
      <div className="max-w-prose px-8 py-6 sm:px-16 sm:py-8">
        <Markdown source={info} />
      </div>
    </CardModal>
  )
}
